import React from 'react';
import { CURRENT_YEAR_NUMBER, generateYearArray } from '~/constants';
import { SelectYear } from './style';

interface SelectYearComponentProps {
  selectedYear: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  currentYear: string; // Recebendo o ano atual como prop
}

const SelectYearComponent: React.FC<SelectYearComponentProps> = ({
  selectedYear,
  onChange,
  currentYear,
}) => {
  const years = generateYearArray(2023);
  const parsedCurrentYear = Number(currentYear);

  if (parsedCurrentYear > CURRENT_YEAR_NUMBER) {
    years.push(parsedCurrentYear);
  }

  return (
    <SelectYear name="select" value={selectedYear} onChange={onChange}>
      {years.map(year => (
        <option key={year} value={year.toString()}>
          {year}
        </option>
      ))}
    </SelectYear>
  );
};

export default SelectYearComponent;
