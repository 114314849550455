import currentYear, { Year } from './currentYear';

export const UPDATE_CLASSES_REQUEST_YEAR = 2026;
export const UPDATE_CLASSES_REQUEST_QUARTER = 2;
export const CURRENT_YEAR = currentYear;
export const CURRENT_YEAR_NUMBER = Year;

export function generateYearOptionsArray(
  endYear = 2022,
): { name: string; id: number }[] {
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let year = currentYear; year >= endYear; year -= 1) {
    years.push({ name: year.toString(), id: currentYear - year + 1 });
  }

  return years;
}

export function generateYearArray(endYear = 2022): number[] {
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let year = currentYear; year >= endYear; year -= 1) {
    years.push(year);
  }

  return years;
}
