import { CURRENT_YEAR_NUMBER } from '~/constants';

/* eslint-disable no-plusplus */
interface IPropsInput {
  isValid: boolean;
  value: string;
  required: boolean;
  error: string;
}

const defaultInput = { value: '', error: '', isValid: true };
const requiredTrue = { ...defaultInput, required: true };

const initialInputValue = {
  defaultInput,
  required: requiredTrue,
};

export const isEmpty = (item: string | Object): Boolean => {
  if (typeof item === 'string') {
    return item.trim().length === 0;
  }

  if (item instanceof Object) {
    return Object.entries(item).length === 0;
  }

  return false;
};

export const setInputValue = (value: {
  value: any;
  error?: string;
  required?: true | false;
  isValid?: true | false;
}): IPropsInput => {
  const initialValue = initialInputValue.required;

  if (typeof value === 'object') {
    return { ...initialValue, ...value };
  }

  return { ...initialValue, value };
};

export const validateForm = (state: {
  [key: string]: IPropsInput;
}): { [key: string]: IPropsInput } => {
  const inputsWithError: { [key: string]: IPropsInput } = {};

  Object.entries(state).forEach((input: any) => {
    const [key, { value, required }] = input;

    // Campos obrigatórios
    if (isEmpty(value) && required) {
      inputsWithError[key] = setInputValue({
        value,
        error: 'Campo obrigatório',
      });
    }
  });

  return inputsWithError;
};

export const generateInputValues = (array: any[], required = false): object => {
  const inputs: any = {};

  array.forEach((input: any) => {
    inputs[input] = required
      ? initialInputValue.required
      : initialInputValue.defaultInput;
  });

  return inputs;
};

export const generateDefaultInputValues = (array: any[]): object =>
  generateInputValues(array);

export const generateRequiredInputValues = (array: any[]): object =>
  generateInputValues(array, true);

export const changeMoney = (money: string): string => {
  let number = money.replaceAll(/[^a-z0-9]/gm, '').trim();

  if (number.length === 1) number = `0${number}`;

  number = `${number.substr(0, number.length - 2)}.${number.substr(
    number.length - 2,
  )}`;

  return number;
};

export const formatDecimal = (money: string): string => {
  let number = money.replaceAll(/[^a-z0-9]/gm, '').trim();

  if (number.length === 1) number = `0${number}`;

  number = `${number.substr(0, number.length - 2)}.${number.substr(
    number.length - 2,
  )}`;

  return number.replace(/^(0+)(\d)/g, '$2');
};

export const getDateOfWeek = (
  lesson: number,
  quarter: number,
  targetDay: number,
): Date => {
  const baseDate = new Date(`${CURRENT_YEAR_NUMBER}-01-01T00:00:00`);
  const baseDayOfWeek = baseDate.getDay();
  const quarterStartWeek: any = { 1: 0, 2: 13, 3: 26, 4: 39 };

  if (baseDayOfWeek !== targetDay) {
    if (baseDayOfWeek > targetDay) {
      baseDate.setDate(baseDate.getDate() + (7 - baseDayOfWeek + targetDay));
    } else {
      baseDate.setDate(baseDate.getDate() + (targetDay - baseDayOfWeek));
    }
  }

  baseDate.setDate(
    baseDate.getDate() + 7 * (lesson + quarterStartWeek[quarter] - 1),
  );

  if (baseDate.getFullYear() !== CURRENT_YEAR_NUMBER) {
    throw new Error('The calculated date is outside the current year.');
  }

  return baseDate;
};

export const getDay = (day: string): number => {
  const enumDay = {
    Domingo: 0,
    'Segunda-feira': 1,
    'Terça-feira': 2,
    'Quarta-feira': 3,
    'Quinta-feira': 4,
    'Sexta-feira': 5,
    Sabado: 6,
  };
  return (
    enumDay[
      day as
        | 'Domingo'
        | 'Segunda-feira'
        | 'Terça-feira'
        | 'Quarta-feira'
        | 'Quinta-feira'
        | 'Sexta-feira'
        | 'Sabado'
    ] || enumDay.Domingo
  );
};

export const countLessonsByYear = (year: number): any => {
  const today = new Date();
  let count = 0;
  let date = new Date(year, 0, 1);

  if (year === today.getFullYear()) {
    date = new Date(year, 0, 1);
    while (date <= today) {
      if (date.getDay() === 0) {
        count++;
      }
      date.setDate(date.getDate() + 1);
    }
    return count;
  }

  while (date.getFullYear() === year) {
    if (date.getDay() === 0) {
      count++;
    }
    date.setDate(date.getDate() + 1);
  }

  return count;
};

export const maskPhone = (value: string): string => {
  const numero = value.replace(/\D/g, '');
  let mascara = '';

  if (numero.length <= 10) {
    mascara = numero.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
  } else if (numero.length > 10) {
    mascara = numero.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  }

  return mascara;
};

export const convertToTimestamp = (dateStr: string): Date => {
  const parts = dateStr.split('/');

  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1;
  const year = parseInt(parts[2], 10);

  const date = new Date(year, month, day);

  return date;
};
