import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import pt from 'date-fns/esm/locale/pt/index.js';
import { countLessonsByYear } from '~/utils';
import {
  ChartComponent,
  Header,
  Loading,
  Return,
  SelectQuarterComponent,
  TablePage,
} from '~/components';
import frequencyService from '~/services/frequencyService';

import { AiFillSignal } from 'react-icons/ai';
import { FaListUl } from 'react-icons/fa';
import { IoIosArrowDown } from 'react-icons/io';

import { ListFrequencyReportResponseDTO } from '~/services/dtos/frequencyServiceDTO';
import { CURRENT_YEAR } from '~/constants';
import SelectYearComponent from '~/components/SelectYearComponent';

import {
  CalculationDetailsInfo,
  Container,
  Content,
  ContentMembers,
  Headline,
  Percentage,
  ResultSection,
  ResultTag,
  ResultTipesWithDivider,
  SelectTypeResultContainer,
  SubTitle,
  Title,
} from './styles';
import ModalDetailsCalculation from './ModalDetailsCalculation';

const Reports: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState(CURRENT_YEAR);
  const [selectedQuarter, setSelectedQuarter] = useState('');
  const [activeTag, setActiveTag] = useState<number>(1);
  const [averageMembers, setAverageMembers] = useState<number>(0);
  const [averagePercentage, setAveragePercentage] = useState<number>(0);
  const [averagePresence, setAveragePresence] = useState<number>(0);
  const [frequenciesCount, setFrequenciesCount] = useState<number>(0);

  const [isVisibleModalInfo, setIsVisibleModalInfo] = useState(false);

  const [dataSource, setDataSource] = useState<
    ListFrequencyReportResponseDTO[]
  >([]);

  const [frequenciesNumber, setFrequenciesNumber] = useState<number>(0);

  const fetchData = async (year?: any, quarter?: string) => {
    setLoading(true);
    const [resReport, resCount] = await Promise.all([
      frequencyService.ListFrequencyReport(year, quarter),
      frequencyService.CountFrequencies(year),
    ]);

    const totalPercentage = resReport.reduce(
      (accumulator, currentValue) => accumulator + currentValue.percentage,
      0,
    );

    const totalMembers = resReport.reduce(
      (accumulator, currentValue) => accumulator + currentValue.membersNumber,
      0,
    );

    const totalPresence = resReport.reduce(
      (accumulator, currentValue) => accumulator + currentValue.totalPresence,
      0,
    );

    const averagePercentage = Number(totalPercentage / resReport.length);
    const averageMembers = Number(totalMembers / resReport.length);
    const averagePresence = Number(totalPresence / resReport.length);

    setAveragePercentage(averagePercentage);
    setAverageMembers(averageMembers);
    setAveragePresence(averagePresence);
    setFrequenciesNumber(resCount.number);
    setDataSource(resReport);

    setLoading(false);
  };

  useEffect(() => {
    fetchData(selectedYear, selectedQuarter);
    setFrequenciesCount(countLessonsByYear(Number(selectedYear)));
  }, [selectedYear, selectedQuarter]);

  const columns = [
    {
      title: 'Data',
      dataIndex: 'date',
      key: 'date',
      render: (e: any) =>
        `${e && format(new Date(e), "dd 'de' MMMM 'de' y", { locale: pt })}`,
    },
    {
      title: 'Presentes',
      dataIndex: 'totalPresence',
      key: 'totalPresence',
    },
    {
      title: '%',
      dataIndex: 'percentage',
      key: 'percentage',
      render: (percentage: any) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p>{Math.floor(percentage)}%</p>
        </div>
      ),
    },
  ];

  if (loading) return <Loading />;

  return (
    <>
      {isVisibleModalInfo && (
        <ModalDetailsCalculation
          onClick={() => setIsVisibleModalInfo(false)}
          list={dataSource}
          totalPresence={averagePresence}
          totalMembers={averageMembers}
          totalPercentage={averagePercentage}
        />
      )}
      <Container>
        <Header />
        <Return />
        <Content>
          <Title>Relatórios</Title>
          <SubTitle>
            Frequências Enviadas: {frequenciesNumber}/{frequenciesCount}{' '}
          </SubTitle>
        </Content>

        <Content>
          <Headline>Percentual de Presença</Headline>

          <Percentage>
            {averagePercentage ? averagePercentage?.toFixed() : '0'}%
          </Percentage>
          <CalculationDetailsInfo onClick={() => setIsVisibleModalInfo(true)}>
            Detalhes do cálculo
            <IoIosArrowDown />
          </CalculationDetailsInfo>

          <ContentMembers>
            Membros/Congregados:
            {averageMembers ? averageMembers?.toFixed() : '0'}
          </ContentMembers>
          <SelectTypeResultContainer>
            <ResultTipesWithDivider>
              <ResultTag
                className={activeTag === 1 ? 'active' : ''}
                onClick={() => setActiveTag(1)}
              >
                <FaListUl /> Lista
              </ResultTag>
              <div
                style={{ border: '1px', color: '#000000', height: '20px' }}
              />
              <ResultTag
                className={activeTag === 2 ? 'active' : ''}
                onClick={() => setActiveTag(2)}
              >
                <AiFillSignal /> Gráfico
              </ResultTag>
            </ResultTipesWithDivider>
          </SelectTypeResultContainer>
          <ResultSection>
            <SelectQuarterComponent
              selectedQuarter={selectedQuarter}
              onChange={e => setSelectedQuarter(e.target.value)}
            />
            <SelectYearComponent
              selectedYear={selectedYear}
              onChange={e => setSelectedYear(e.target.value)}
              currentYear={CURRENT_YEAR}
            />
          </ResultSection>
          <Headline>{selectedYear}</Headline>
          <>
            {activeTag === 1 && (
              <TablePage
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                rowKey="id"
              />
            )}
            {activeTag === 2 && <ChartComponent dataSource={dataSource} />}
          </>
        </Content>
      </Container>
    </>
  );
};

export default Reports;
